// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-game-tsx": () => import("./../../../src/pages/game.tsx" /* webpackChunkName: "component---src-pages-game-tsx" */),
  "component---src-pages-gamemaster-tsx": () => import("./../../../src/pages/gamemaster.tsx" /* webpackChunkName: "component---src-pages-gamemaster-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join.tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-pages-slide-tsx": () => import("./../../../src/pages/slide.tsx" /* webpackChunkName: "component---src-pages-slide-tsx" */)
}

