import React from "react";
import { RecoilRoot } from "recoil";
import { ThemeProvider } from "styled-components";
import { defaultTheme, GlobalFonts } from "@metyis-ds/tokens";
import "tailwindcss/tailwind.css";
import './global.css';

const tntTheme = {"mode":"light","name":"default_theme","colors":
  {"purple":{"light":"#D3C3FF","medium":"#936DFA","dark":"#5537A6"},
  "pink":{"light":"#FFD7FD","medium":"#E26FDD","dark":"#9C4899"},
  "red":{"light":"#FCCCCD","medium":"#F8555B","dark":"#A61E24"},
  "orange":{"light":"#FFD3AE","medium":"#FF9E4E","dark":"#C96A1A"},
  "yellow":{"light":"#FFEFB8","medium":"#FFC907","dark":"#D6A909"},
  "blue":{"light":"#BDE0FE","medium":"#4FA0EB","dark":"#1A548B"},
  "green":{"light":"#C5FAE8","medium":"#34DDA5","dark":"#278464"},
  "grey":{"light":"#C8D3D9","medium":"#4E5559","dark":"#080809"},
  "white":{"light":"#FFFFFF","medium":"#F7F9FA","dark":"#EDF1F3"},
  "alert":{"alert100":"red"},"background":{"overlay":"rgba(4, 4, 15, 0.7)"}},
  "fontFamily":"neue-haas-grotesk-display","fontSize":18,"spaceUnit":{"xxs":"4px","xs":"8px","sm":"12px","md":"16px","lg":"24px","xl":"32px","xxl":"48px","xxxl":"64px"},
  "spacing":{"squishsm":{"top":"4px","left":"12.307692307692307px"},
  "squishmd":{"top":"8px","left":"16px"},"squishlg":{"top":"16px","left":"32px"},
  "stretchsm":{"left":"4px","top":"12.307692307692307px"},"stretchmd":{"left":"8px","top":"16px"},
  "stretchlg":{"left":"16px","top":"32px"}},
  "typography":{"header":{"h1bold":{"fontSize":"80px","fontWeight":700,"lineHeight":"96px","fontFamily":"neue-haas-grotesk-display"},
  "h1roman":{"fontSize":"80px","fontWeight":400,"lineHeight":"92px","fontFamily":"neue-haas-grotesk-text"}},
  "title":{"title1bold":{"fontSize":"64px","lineHeight":"77px","fontWeight":700,"fontFamily":"neue-haas-grotesk-display"},
  "title1light":{"fontSize":"64px","lineHeight":"77px","fontWeight":400,"fontFamily":"neue-haas-grotesk-display"},
  "title2bold":{"fontSize":"48px","fontWeight":700,"lineHeight":"58px","fontFamily":"neue-haas-grotesk-display"},
  "title2medium":{"fontSize":"48px","fontWeight":500,"lineHeight":"57px","fontFamily":"neue-haas-grotesk-display"},
  "title2light":{"fontSize":"48px","fontWeight":400,"lineHeight":"54px","fontFamily":"neue-haas-grotesk-display"},
  "title3bold":{"fontSize":"40px","fontWeight":700,"lineHeight":"48px","fontFamily":"neue-haas-grotesk-display"},
  "title3medium":{"fontSize":"40px","fontWeight":500,"lineHeight":"48px","fontFamily":"neue-haas-grotesk-display"},
  "title3light":{"fontSize":"40px","fontWeight":400,"lineHeight":"48px","fontFamily":"neue-haas-grotesk-display"}},
  "body":{"body1bold":{"fontSize":"32px","fontWeight":700,"lineHeight":"38px","fontFamily":"neue-haas-grotesk-display"},
  "body1light":{"fontSize":"32px","fontWeight":400,"lineHeight":"36px","fontFamily":"neue-haas-grotesk-display"},
  "body2bold":{"fontSize":"24px","fontWeight":700,"lineHeight":"29px","fontFamily":"neue-haas-grotesk-display"},
  "body2light":{"fontSize":"24px","fontWeight":400,"lineHeight":"29px","fontFamily":"neue-haas-grotesk-display"}},
  "text":{"text1bold":{"fontSize":"18px","fontWeight":700,"lineHeight":"23px","fontFamily":"neue-haas-grotesk-display"},
  "text1light":{"fontSize":"18px","fontWeight":400,"lineHeight":"22px","fontFamily":"neue-haas-grotesk-display"},
  "text2bold":{"fontSize":"16px","fontWeight":700,"lineHeight":"20px","fontFamily":"neue-haas-grotesk-display"},
  "text2roman":{"fontSize":"16px","fontWeight":400,"lineHeight":"20px","fontFamily":"neue-haas-grotesk-text"},
  "text2romanunderline":{"fontSize":"16px","fontWeight":400,"lineHeight":"20px","textDecoration":"underline","fontFamily":"neue-haas-grotesk-text"},
  "text3bold":{"fontSize":"14px","fontWeight":700,"lineHeight":"18px","fontFamily":"neue-haas-grotesk-display"},
  "text3light":{"fontSize":"14px","fontWeight":400,"lineHeight":"17px","fontFamily":"neue-haas-grotesk-display"},
  "text4bold":{"fontSize":"12px","fontWeight":700,"lineHeight":"15px","fontFamily":"neue-haas-grotesk-display"},
  "text4light":{"fontSize":"12px","fontWeight":400,"lineHeight":"15px","fontFamily":"neue-haas-grotesk-display"}}},
  "breakpoints":{"mobile-1":700,"mobile-2":900,"tablet-1":1140}}

const finalTheme = Object.assign({}, defaultTheme, tntTheme);

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={finalTheme}>
    <RecoilRoot>{element}</RecoilRoot>
    <GlobalFonts />
  </ThemeProvider>
);
